import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import PodcastPlayer from "../PodcastPlayer";
import moment from "moment";
import SVG from "react-inlinesvg";

export function EpisodeItem({ item, productId, toggleEpisode, isSelectEpisode, DragHandle, sortIndex, productData, toggleRequired, loading = false }) {
  function summary(text) {
    if(!text || typeof text != "string")
      return "";

    let words = text.split(" ").slice(0, 20).join(" ").substr(0, 200);
    if(words.length < text.length)
      words += "...";

    return words;
  }

  return (
    <tr>
      <td className={DragHandle ? "pl-0" : "pl-6"}>
        <div className="drag-wrapper">
          {DragHandle &&
            <div className="drag-btn">
              <DragHandle />
            </div>
          }

          {/*<label className="checkbox checkbox-lg checkbox-single">*/}
          {/*  <input type="checkbox" value="1" />*/}
          {/*  <span />*/}
          {/*</label>*/}

          <label className="checkbox checkbox-lg checkbox-single">
            <input
              type="checkbox"
              value={item._id}
              checked={isSelectEpisode}
              onChange={toggleEpisode}
            />
            <span />
          </label>
        </div>
      </td>
      <td>
        <Link
          to={`/products/${productId}/episodes/${item._id}`}
          className="text-dark-75 text-hover-primary mb-1 font-size-lg"
        ><span className="font-weight-bold text-muted font-size-sm">EP {sortIndex + 1}</span><br />{item.title}</Link>
      </td>

      <td className="text-center">
        <a href="#" onClick={ev => {
          ev.preventDefault();
          toggleRequired(item);
        }} className={loading == "required_" + item._id ? "loading spinner" : ""}>
          <SVG src="/media/def-image/icons/asterisk.svg" className={"required-icon" + (item.required ? " active" : "")} />
        </a>
      </td>

      <td>


        <PodcastPlayer
          audioUrl={item.publicAudio?.audioUrl}
          originalFileUrl={item.originalFileUrl}
          finishedProcessing={item.finishedProcessing}
          failedProcessing={item.failedProcessing}
          processingFriendlyError={item.processingFriendlyError}
          product={productData}
        />

      </td>

      <td className="text-wrap">
        {Array.isArray(item.releaseByTags) && item.releaseByTags.map(t => <span key={t} className="badge mr-2">{t}</span>)}
      </td>

      {productData.releaseMode == 2 && <td>{sortIndex == 0 ? "Immediately" : (
        item.releaseDate
          ? moment(item.releaseDate).locale("en").format('DD MMM YYYY HH:mm')
          : "Not set"
      )}</td>}

      {(productData.livePeriod == 1 || productData.livePeriod == 2) && <td>{(() => {
        if(item.releaseOrder == 1 || sortIndex == 0)
          return "Never";

        let value;
        if(productData.livePeriod == 1) {
          let m = 1;
          if(productData.productExpiresMode == "weeks") m = 7;
          if(productData.productExpiresMode == "months") m = 30;
          value = productData.productExpires * m;
        } else {
          let m = 1;
          if(item.expireAfterPeriod == "weeks") m = 7;
          if(item.expireAfterPeriod == "months") m = 30;
          value = item.expireAfterNumber * m;
        }
        return value + " day" + (value != 1 ? "s" : "");
      })()}</td>}

      {/*(productData.releaseMode == 0 || productData.releaseMode == 1) && <td>{item.releaseOrder}</td>*/}

      {productData.releaseMode == 1 && <td>{(item => {
        let periods = {
          "minutes": item.dripReleaseNumber == 1 ? "minute" : "minutes",
          "hours": item.dripReleaseNumber == 1 ? "hour" : "hours",
          "days": item.dripReleaseNumber == 1 ? "day" : "days",
          "weeks": item.dripReleaseNumber == 1 ? "week" : "weeks",
          "months": item.dripReleaseNumber == 1 ? "month" : "months",
        },
          after = {
            "last-released": "after previous",
            "last-played": "after previous is played"
          };

        if(item.releaseOrder == 1 || item.dripImmediateRelease)
          return "Immediately";

        if(item.dripReleaseNumber === 0 || item.dripReleaseNumber === "0")
          return "Immediately " + after[item.dripReleaseAfter || "last-released"];

        return item.dripReleaseNumber + " " + periods[item.dripReleasePeriod] + " " + after[item.dripReleaseAfter || "last-released"];
      })(item)}</td>}

      <td>
        {item.draft === -1 && <Badge variant="success" className="episode-table-label-live">Live</Badge>}
        {item.draft === 1 && <Badge variant="secondary" className="episode-table-label-draft">Draft</Badge>}
      </td>
    </tr>
  );
}