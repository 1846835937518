import _ from "lodash";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import { useHistory } from "react-router-dom";
import { checkLimits } from "../../plans";

function getObject(tag, { contactsCount = true }) {
  return {
    id: tag.name,
    label: (
      <>
        {tag.name}&nbsp;
        {contactsCount && <small>({tag.contactsCount})</small>}
      </>
    )
  };
}

function TagDropdown({ user, product, onCreateTagRequest, contactsCount = true, className = "", placeholder, ...props }) {
  const history = useHistory(),
    [options, setOptions] = useState([]),
    [groups, setGroups] = useState();

  async function createLinkOnClick(ev) {
    ev.preventDefault();
    if(!await checkLimits.canAddTag(user))
      return;
    if(onCreateTagRequest)
      onCreateTagRequest();
    else
      history.push("/tags");
  }

  function usedInEpisodes(tag) {
    if(!product || !Array.isArray(product.episodes) || !product.episodes.length)
      return false;

    for(let episode of product.episodes) {
      if(!Array.isArray(episode.releaseByTags) || !episode.releaseByTags.length)
        continue;

      if(episode.releaseByTags.includes(tag.id))
        return true;
    }

    return false;
  }

  function usedInPrices(tag) {
    if(!product || !Array.isArray(product.prices) || !product.prices.length)
      return false;

    for(let price of product.prices)
      if(price.tags.includes(tag.id))
        return true;

    return false;
  }

  function loadOptions() {
    let allTags = user.tags.map(t => getObject(t, { contactsCount }));

    if(!product) {
      setGroups(null);
      setOptions(allTags);
      return;
    }

    setGroups([
      {
        name: "this-show",
        label: "Used in this show",
        emptyDropdownText: "You haven’t used any tags in this show."
      },
      {
        name: "all",
        label: "All tags"
      }
    ]);

    for(let tag of allTags) {
      tag.groups = ["all"];
      if(usedInEpisodes(tag) || usedInPrices(tag))
        tag.groups.push("this-show");
    }

    setOptions(allTags);
  }

  useEffect(() => {
    if(!user)
      return;
    loadOptions();
  }, [user, product]);

  if(!user)
    return <></>;

  return (
    <CustomSelect
      image={false}
      options={options}
      groups={groups}
      placeholder={placeholder}
      className={"tags-dropdown " + className}
      dropdownFooter={<a href="#" onClick={createLinkOnClick} className="d-block">+ Create tag</a>}
      emptyText={<>You don’t have any tags. <a href="#" onClick={createLinkOnClick}>Create tag</a></>}
      emptyDropdownText="There are no more tags to select." {...props} />
  );
}

export default injectIntl(
  connect(
    (state) => ({
      user: state.auth.user
    })
  )(withRouter(TagDropdown))
);
