import React from "react";
import SVG from "react-inlinesvg";
import UpgradeLink from "../../../../generic/UpgradeLink";
import { Switch } from "@material-ui/core";

export default function StepSampleEpisodes({ formik, user }) {
    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-column flex-md-row w-full align-items-md-center">
                    <h3 className="card-title m-0 fill">
                        Sample episodes
                        <UpgradeLink className="ml-3" user={user} />
                    </h3>

                    <label className="m-0 d-flex align-items-center">
                        <Switch className="blue-switch mr-0" checked={!!formik.values.enableSampleEpisodes} onChange={ev => formik.setFieldValue("enableSampleEpisodes", ev.target.checked)} />
                        <span className={"pl-2 flex-grow-1 " + (formik.values.enableSampleEpisodes ? "text-primary" : "")}>
                            Samples {formik.values.enableSampleEpisodes ? "on" : "off"}
                        </span>
                    </label>
                </div>

                <p className="text-muted">Listeners can access sample episodes in the Hiro app for free and pay to unlock all episodes. You can sell access using the Hiro sales page or your own URL. <a href="#">Learn more</a></p>
            </div>

            {!!formik.values.enableSampleEpisodes && (
                <div className="card-body listener-access-email">
                    <div className="form-group">
                        <label className="form-label">Link to unlock full access</label>

                        <div className="d-flex episode-access-options">
                            <label className={"option with-radio " + (formik.values.unlockLink == "sales-page" ? "active" : "")}>
                                <input type="radio" name="unlockLink" value="sales-page" onChange={ev => ev.target.checked && formik.setFieldValue("unlockLink", "sales-page")} />
                                <span className="option-label">
                                    <strong>Hiro sales page</strong><br />
                                    Send listeners to the Hiro sales page to unlock full access
                                </span>
                            </label>

                            <label className={"option with-radio " + (formik.values.unlockLink == "custom-url" ? "active" : "")}>
                                <input type="radio" name="unlockLink" value="custom-url" onChange={ev => ev.target.checked && formik.setFieldValue("unlockLink", "custom-url")} />
                                <span className="option-label">
                                    <strong>My own URL</strong><br />
                                    Send listeners to my own sales page URL to unlock full access
                                </span>
                            </label>

                            <label className={"option with-radio " + (formik.values.unlockLink == "instant" ? "active" : "")}>
                                <input type="radio" name="unlockLink" value="instant" onChange={ev => ev.target.checked && formik.setFieldValue("unlockLink", "instant")} />
                                <span className="option-label">
                                    <strong>No sales page</strong><br />
                                    Give listeners instant access to all episodes without signing up
                                </span>
                            </label>
                        </div>
                    </div>

                    {formik.values.unlockLink == "custom-url" && (
                        <div className="form-group">
                            <label className="form-label">My own URL</label>
                            <input type="text" name="unlockLinkUrl" className="form-control" placeholder="https://mywebsite.com" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}