import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Dropdown } from "react-bootstrap";
import { FormControlLabel, Switch } from "@material-ui/core";
import api from "../../../../redux/api";
import { formatLength, toastMessage } from "../../../helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import { ProductLogo } from "../../../../_metronic/layout/components/productLogo";
import SVG from "react-inlinesvg";

function ProductItem({ item, history, setProducts, dispatch }) {
  let [ resendEmailLoading, setResendEmailLoading ] = useState(false);

  const releaseModeValues = {
    0: 'Immediately',
    1: 'Dripped',
    2: 'Date',
  };

  const [ state, setState ] = React.useState({ draft: item.draft, });

  function setUpdateDraft(draft) {
    setState({ ...state, draft });

    api.product.editProduct({
      product: {
        _id: item._id,
        draft,
      }
    }).then(res => {
      if(res) {
        if(draft) {
          toastMessage.success('Product has been successfully removed from publication.')
        } else {
          toastMessage.success('Product published successfully.')
        }

        api.product.getProducts().then(resp => {
          dispatch(setProducts(resp));
        });
      }
    });
  }

  let resendEmail = async id => {
    setResendEmailLoading(true);
    await api.product.resendImportEmail(id);
    toastMessage.success("Email sent.");
    setResendEmailLoading(false);
  };

  return <>
    <tr className={item.pendingOwnership ? "pending" : ""}>

      <td className="pr-0">
        <div className="d-flex flex-wrap align-items-center">
          <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-0" style={{ overflow: 'hidden' }}>
            <ProductLogo product={item} className="symbol-label" />
          </div>
        </div>
      </td>

      <td width="100%" className="text-wrap">
        <div className="d-flex flex-wrap align-items-center">


          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <Link to={`/products/${item._id}`} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
              {item.name}
            </Link>

            {!item.pendingOwnership && <span className="text-muted font-size-sm font-weight-bold mt-1">{item.episodes.length} Episodes </span>}
          </div>
        </div>
      </td>

      {item.pendingOwnership
        ? <td colSpan={3} align="center"><button className={"btn btn-primary " + (resendEmailLoading ? "loading spinner" : "")} onClick={ev => {
          ev.preventDefault();
          resendEmail(item._id);
        }}>Resend email</button></td>
        : <>
          <td className="text-center">{formatLength(item.totalDuration)} </td>
          <td className="text-center">{item.episodes.length ? item.stats.listenersCount.current : 0} </td>
          <td className="text-center">{releaseModeValues[ item.releaseMode ]}</td>
        </>}

      <td className="flex-none text-center">

        <FormControlLabel className="m-0"
          control={<Switch
            checked={state.draft === 0 && !item.pendingOwnership}
            disabled={item.pendingOwnership}
            onChange={event => setUpdateDraft(event.target.checked ? 0 : 1)}
            value={1}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            name="draft"
            className="MuiSwitch-root-live mr-0"
          />}
          label={state.draft === 1 ? 'Draft' : 'Live'}
        />
      </td>

      <td>
        <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline" alignRight>
          <Dropdown.Toggle>
            <SVG src={toAbsoluteUrl("/media/def-image/icons/menu-2.svg")} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to={"/products/" + item._id + "/episodes"}>
              <div className="icon">
                <SVG src={toAbsoluteUrl("/media/def-image/icons/episodes-2.svg")} />
              </div>
              Episodes
            </Link>
            <Link className="dropdown-item" to={"/products/" + item._id + "/listeners"}>
              <div className="icon">
                <SVG src={toAbsoluteUrl("/media/def-image/icons/listeners.svg")} />
              </div>
              Listeners
            </Link>
            <Link className="dropdown-item" to={"/products/" + item._id + "/settings"}>
              <div className="icon">
                <SVG src={toAbsoluteUrl("/media/def-image/icons/settings-2.svg")} />
              </div>
              Settings
            </Link>
            <div className="separator" />
            <Link className="dropdown-item" to={"/delete-product/" + item._id}>
              <div className="icon">
                <SVG src={toAbsoluteUrl("/media/def-image/icons/delete.svg")} />
              </div>
              Delete Product
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  </>;
}

export default injectIntl(connect(
  null,

  dispatch => ({
    ...actions.product,
    dispatch,
  }),
)(ProductItem));
